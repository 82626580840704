import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Button, Col, Row, UncontrolledTooltip } from 'reactstrap'

import { platformHelper } from '../lib/helpers'
import { useAuth } from '../providers/AuthProvider'
import LanguageDropdown from './LanguageDropdown'
import Logo from './Logo'
import ProfileMenu from './ProfileMenu'

const Header = ({ title, firebase }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const { viewCountAndLimit, userData, currentPlan, authUser } = useAuth() ?? {}
  const platform = platformHelper(userData)

  const isLoggedIn = () => {
    return userData?.userId
  }

  const goPricePage = async () => {
    if (platform.pricingUrl.match(/^http/))
      window.open(platform.pricingUrl, '_blank')
    else await router.push(platform.pricingUrl)
  }

  const showUsage = useMemo(() => {
    if (viewCountAndLimit) {
      let { limit, viewCount } = viewCountAndLimit

      if (limit > 0) {
        const usageRate = (viewCount / limit) * 100

        if (usageRate >= 100) {
          return (
            <>
              <Badge color="danger" className="mr-3 p-2" id="usageIndicator">
                <i className="bx bx-bell bx-tada" /> {usageRate.toFixed(1)}%
              </Badge>
              <UncontrolledTooltip placement="right" target="usageIndicator">
                {t(
                  'You have reached your monthly usage cap! Click to upgrade and increase your limit.'
                )}
              </UncontrolledTooltip>
            </>
          )
        } else if (usageRate > 80) {
          return (
            <>
              <Badge color="warning" className="mr-3 p-2" id="usageIndicator">
                {usageRate.toFixed(1)}%
              </Badge>
              <UncontrolledTooltip placement="right" target="usageIndicator">
                {t(
                  'You are about to reach your monthly usage cap! Click to upgrade and increase your limit.'
                )}
              </UncontrolledTooltip>
            </>
          )
        } else {
          return (
            <>
              <Badge color="success" className="mr-3 p-2" id="usageIndicator">
                {usageRate.toFixed(1)}%
              </Badge>
              <UncontrolledTooltip placement="right" target="usageIndicator">
                {t('Current months usage limit')}
              </UncontrolledTooltip>
            </>
          )
        }
      }
    }
  }, [viewCountAndLimit])

  const usageWarningBar = useMemo(() => {
    if (viewCountAndLimit) {
      let { limit, viewCount } = viewCountAndLimit

      if (currentPlan.planname === 'Free') {
        const usageRate = (viewCount / limit) * 100
        if (usageRate >= 100) {
          return (
            <div className="bg-danger text-white text-center p-3">
              <Row className="align-items-center align-content-center justify-content-center">
                <Col className="pb-1 pb-sm-0" sm={1}>
                  <i className="mdi mdi-alert-outline mdi-24px" />
                </Col>
                <Col className="pb-3 pb-sm-0" sm={9}>
                  <strong>
                    {t(
                      'You have reached 100% of your view limit. Displays are stopped.'
                    )}
                    <br />
                    {t('Upgrade now to restart displays.')}
                  </strong>
                </Col>
                <Col sm={2}>
                  <button
                    onClick={goPricePage}
                    className="btn btn-hollow-custom"
                  >
                    {t('Upgrade')}
                  </button>
                </Col>
              </Row>
            </div>
          )
        } else if (usageRate > 0) {
          return (
            <div className="bg-success text-white text-center p-3">
              <Row className="align-items-center align-content-center justify-content-center">
                <Col className="pb-1 pb-sm-0" sm={1}>
                  <i className="mdi mdi-alert-outline mdi-24px" />
                </Col>
                <Col className="pb-3 pb-sm-0" sm={9}>
                  <strong>
                    {t(
                      'You are currently using the free version, limited to 100 views.'
                    )}
                    <br />
                    {t('Upgrade now to get unlimited everything.')}
                  </strong>
                </Col>
                <Col sm={2}>
                  <button
                    onClick={goPricePage}
                    className="btn btn-hollow-custom"
                  >
                    {t('Upgrade')}
                  </button>
                </Col>
              </Row>
            </div>
          )
        } else {
          return null
        }
      }
    } else return null
  }, [viewCountAndLimit])

  const clicked = async () => {
    firebase.refreshKlaviyo(userData)
  }

  const ROUTES = useMemo(() => {
    return [
      {
        id: 'route-display',
        value: 'displays',
        href: '/displays',
        label: 'Dashboard',
        icon: 'mdi-monitor-star'
      },
      {
        id: 'route-leads',
        value: 'lists',
        href: `/lists/${userData.lists[0].id}`,
        label: 'Leads',
        icon: 'mdi-account-outline'
      },
      {
        id: 'route-coupons',
        value: 'coupon',
        href: platform.type === 'generic' ? '/coupons' : '/coupon-histories',
        label: platform.type === 'generic' ? 'Coupons' : 'Coupon history',
        icon: 'mdi-ticket-percent-outline'
      },
      {
        id: 'route-email-templates',
        value: 'email-templates',
        href: '/email-templates',
        label: 'Email Templates',
        icon: 'mdi-email-edit-outline'
      }
    ]
  }, [userData, platform])

  const SelectedRouteIndicator = ({ value }) => {
    const currentPath = router.pathname
    const isActive = currentPath.includes(value)

    return (
      <>
        <div
          className={`route-indicator ${!value.includes('displays') ? 'bg-white' : 'bg-base'} ${isActive ? 'show' : 'hide'}`}
        />
        <style jsx>{`
          .show {
            display: block;
          }
          .hide {
            display: none;
          }
          .route-indicator {
            position: absolute;
            bottom: -10px;
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
          }
          .bg-base {
            background: rgb(244 244 247);
          }
          .bg-white {
            background: #fff;
          }
        `}</style>
      </>
    )
  }

  return (
    <header id="page-topbar">
      <nav className="navbar-header navbar-dark px-1 px-lg-5">
        <div className="logo-wrapper mr-2 mr-lg-4">
          <div className="navbar-brand-box">
            <Link href="/">
              <div
                className="logo-sm d-flex align-items-center"
                style={{ width: '130px' }}
              >
                <Logo mode="light" />
              </div>
            </Link>
          </div>
        </div>
        <div className="navbar-nav d-flex">
          <div className="navbar-main-menu">
            {isLoggedIn() && (
              <div className="d-flex w-100">
                {ROUTES.map((route) => {
                  return (
                    <div key={route.id} className="relative d-flex">
                      <Link
                        href={route.href}
                        className="nav-link d-flex align-items-center mr-2 relative"
                      >
                        <i className={`mdi ${route.icon} mdi-18px mr-2`} />
                        <span className="d-none d-xl-inline">
                          {t(route.label)}
                        </span>
                      </Link>
                      <SelectedRouteIndicator value={route.value} />
                    </div>
                  )
                })}
                <Button id="my-button" className="d-none" onClick={clicked}>
                  Execute
                </Button>
              </div>
            )}
          </div>

          <div className="navbar-user-menu">
            <LanguageDropdown />
            <ProfileMenu
              platform={platform}
              userData={userData}
              firebase={firebase}
              authUser={authUser}
              currentPlan={currentPlan}
            />
          </div>
        </div>
      </nav>
      {usageWarningBar}

      <style jsx>
        {`
          .with-badge {
            position: relative;
          }
          .badge {
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        `}
      </style>
      <style jsx global>
        {`
          #usageIndicator .bx-bell::before {
            font-size: 1.5em;
            vertical-align: middle;
            padding-right: 5px;
          }
          .btn-hollow-custom {
            background-color: transparent;
            border: 1px solid #fff;
            color: #fff;
            cursor: pointer;
          }
        `}
      </style>
    </header>
  )
}

export default Header
