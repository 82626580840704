import axios from 'axios'
import Cookies from 'js-cookie'
import { AuthAction, useAuthUser, withAuthUser } from 'next-firebase-auth'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { AuthProvider } from '../providers/AuthProvider'
import { DisplayProvider } from '../providers/DisplayProvider'
import { FirebaseProvider } from '../providers/FirebaseProvider'
import { ImageEditorProvider } from '../providers/ImageEditorProvider'
import analyticsHelper from '../utils/analyticsHelper'
import { getFirebaseHandler } from '../utils/firebaseHandler'
import FullPageLoader from './FullPageLoader'
import Header from './Header'

const PrivateLayout = ({ children, error, title }) => {
  const authUser = useAuthUser()
  const { pathname } = useRouter()

  const [userData, setUserData] = useState(null)
  const [currentPlan, setCurrentPlan] = useState()
  const [viewCountAndLimit, setViewCountAndLimit] = useState({
    viewCount: 0,
    limit: 100
  })
  const [retry, setRetry] = useState(0)

  const firebase = getFirebaseHandler()
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { authUser, userData, firebase, title })
  )

  const isDisplayView = () => {
    return pathname.match(/^\/displays\/(?!(create|starters))/)
  }

  const breakUsageCache = async (uid) => {
    try {
      const scriptHost = process.env.NEXT_PUBLIC_SCRIPT_HOST
      const res = await axios.post(
        `${scriptHost}/delete-redis-usage-cache/${uid}`
      )
    } catch (e) {
      console.error(e)
    }
  }

  let storedState = {}
  const setAllUserData = async (userData) => {
    const currentPlan = await firebase.getCurrentPlan(userData)
    storedState.currentPlan = currentPlan
    setCurrentPlan(currentPlan)
    setUserData(userData)
    setUsageLimit(userData)
  }

  const setUsageLimit = (userData) => {
    analyticsHelper
      .getAnalyticsByUser(userData.userId)
      .then((data) => {
        setViewCountAndLimit(data)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const didPlanChange = async (newUserData) => {
    try {
      const newCurrentPlan = await firebase.getCurrentPlan(newUserData)
      return newCurrentPlan.planname !== storedState.currentPlan.planname
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const fetchUserData = async () => {
    //setUser on firebase handler
    firebase.setUserHandler(authUser, setUserData)
    try {
      const userData = await firebase.getUserData(authUser.id)

      window.plUser.id = authUser.id
      window.plUser.admin = userData.admin

      // check if groove exists
      if (window.groove) {
        groove.widget.identifyContact('contact_email', userData?.email)
        console.log(userData.email, 'email set')
      }

      if (userData) {
        await setAllUserData(userData)
      } else {
        await firebase.logout()
      }

      firebase.listenUserDataChanges(authUser.id, async (newUserData) => {
        //const userData = doc.data()
        if (newUserData) {
          if (await didPlanChange(newUserData)) {
            await setAllUserData(newUserData)

            //If a user updates a plan the cached overlimit flag can change. So we should break the cache.
            await breakUsageCache(authUser.id)
          }

          if (!Cookies.get('impersonate-login')) {
            if (window.groove) {
              groove.widget.logout()
            }
            // firebase.refreshCrisp(newUserData)
            // 	.catch(e => {console.error(e)})

            firebase.refreshKlaviyo(newUserData).catch((e) => {
              console.error(e)
            })
          }
        } else {
          await firebase.logout()
        }
      })
    } catch (e) {
      setTimeout(() => {
        if (retry < 3) {
          setRetry(retry + 1)
        } else {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      }, 2000)
    }
  }

  useEffect(() => {
    if (authUser.id) fetchUserData()
  }, [authUser.id])

  return userData && currentPlan ? (
    <>
      <FirebaseProvider firebase={firebase}>
        <AuthProvider
          authUser={authUser}
          userData={userData}
          currentPlan={currentPlan}
          viewCountAndLimit={viewCountAndLimit}
        >
          <Head>
            <title>{title}</title>
            <link
              rel="apple-touch-icon"
              sizes="57x57"
              href="/apple-icon-57x57.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="60x60"
              href="/apple-icon-60x60.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="72x72"
              href="/apple-icon-72x72.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href="/apple-icon-76x76.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="114x114"
              href="/apple-icon-114x114.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href="/apple-icon-120x120.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="144x144"
              href="/apple-icon-144x144.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/apple-icon-152x152.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-icon-180x180.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href="/android-icon-192x192.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="96x96"
              href="/favicon-96x96.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <meta name="theme-color" content="#5c3dc2" />
          </Head>
          <Header title={title} firebase={firebase} userData={userData} />

          <main className="main-content">
            {error ? (
              <div>{error}</div>
            ) : isDisplayView() ? (
              <DisplayProvider>
                <ImageEditorProvider>{childrenWithProps}</ImageEditorProvider>
              </DisplayProvider>
            ) : (
              <>{childrenWithProps}</>
            )}
          </main>
        </AuthProvider>
      </FirebaseProvider>
    </>
  ) : (
    <FullPageLoader />
  )
}
export default withAuthUser({
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  //whenAuthed:AuthAction.REDIRECT_TO_APP,
  LoaderComponent: FullPageLoader
})(PrivateLayout)
