import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect } from 'react'

import { posthogEvent } from '../lib/helpers'
import { syncDataToBrevo } from '../utils/userDataSync'
import { useFirebase } from './FirebaseProvider'

let oldUserData = null

const AuthContext = createContext()

function AuthProvider({
  children,
  userData,
  authUser,
  currentPlan,
  viewCountAndLimit
}) {
  const router = useRouter()

  const { firebase } = useFirebase()

  const syncWithBrevo = async () => {
    if (!userData) return

    const token = await authUser.getIdToken()
    if (!token) return

    if (oldUserData && oldUserData.email !== userData.email) {
      syncDataToBrevo(userData, true, oldUserData.email, token)
    } else {
      syncDataToBrevo(userData, false, '', token)
    }
    oldUserData = userData
  }

  const trackPosthogEvent = async () => {
    if (!userData || !userData?.userId) return

    if (userData?.trackingEvents?.signedUp) return

    await firebase.updateUserData({
      ...userData,
      trackingEvents: { ...userData?.trackingEvents, signedUp: true }
    })
    posthogEvent({ eventType: 'capture', key: 'sign_up' })
  }

  const initializePosthog = async () => {
    posthogEvent({
      eventType: 'identify',
      key: userData.userId,
      value: {
        email: userData?.email ?? '',
        plan: userData?.platform?.currentPlan.toLowerCase() ?? 'free',
        platform: userData?.platform?.name.toLowerCase(),
        platformPlan: userData?.platformPlan ?? '',
        staffAccount: userData?.staffAccount === true
      }
    })
    await trackPosthogEvent()
  }

  useEffect(() => {
    syncWithBrevo()
    initializePosthog()
  }, [router.query.host, userData])

  return (
    <AuthContext.Provider
      value={{ userData, authUser, currentPlan, viewCountAndLimit }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
