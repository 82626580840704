import axios from 'axios'
import { getUserLocale } from 'get-user-locale'
import Cookies from 'js-cookie'
import React, { useCallback } from 'react'

import { useAuth } from '../providers/AuthProvider'

const typesOfGame = ['spin-to-win']
export const getURL = () => {
  const url =
    process?.env?.URL && process.env.URL !== ''
      ? process.env.URL
      : process?.env?.NEXT_PUBLIC_LIVE_URL &&
          process.env.NEXT_PUBLIC_LIVE_URL !== ''
        ? process.env.NEXT_PUBLIC_LIVE_URL
        : 'http://localhost:3000'
  return url.includes('http') ? url : `https://${url}`
}

export const postData = async ({ url, token, data = {} }) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })

  if (res.error) {
    throw error
  }

  return res.json()
}

export const toDateTime = (secs) => {
  var t = new Date('1970-01-01T00:30:00Z') // Unix epoch start.
  t.setSeconds(secs)
  return t
}

export const stringToBoolean = (value) => {
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'false') {
      return false
    } else if (value.toLowerCase() === 'true') {
      return true
    } else {
      return value
    }
  } else {
    return value
  }
}

export const platformHelper = (userData) => {
  let platformCapabilities = {
    usesCustomLogin: false,
    supportsGeneratingCoupons: false,
    pricingUrl: '/pricing',
    type: 'generic',
    showInstall: true,
    showProfile: true,
    showLogout: true,
    displayName: () => {
      let fullName = userData.fullName
      if (userData.firstName && userData.lastName)
        fullName = `${userData.firstName} ${userData.lastName}`

      return fullName || userData.email
    },
    sync: async () => {
      if (Cookies.get('impersonate-login')) return
      const timezone =
        Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'Asia/Tokyo'
      const preferredLanguage = detectLang()
      return await axios.post(`/api/generic/syncInfomation`, {
        userData,
        timezone,
        preferredLanguage
      })
    }
  }

  if (
    userData.platform?.name === 'wix' ||
    userData.platform?.name === 'shopify'
  ) {
    switch (userData.platform.name) {
      case 'shopify':
        platformCapabilities = {
          ...platformCapabilities,
          usesCustomLogin: true,
          supportsGeneratingCoupons: true,
          pricingUrl: '/shopify/pricing',
          type: 'shopify',
          showInstall: false,
          showProfile: false,
          showLogout: false,
          displayName: () =>
            userData['userId']?.replace('.myshopify.com', '') ?? userData.email,
          sync: async () => {
            if (Cookies.get('impersonate-login')) return
            const timezone =
              Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'Asia/Tokyo'
            const preferredLanguage = detectLang()
            return await axios.post(`/api/shopify/syncInfomation`, {
              userData,
              timezone,
              preferredLanguage
            })
          }
        }
        break
      case 'wix':
        platformCapabilities = {
          ...platformCapabilities,
          usesCustomLogin: true,
          supportsGeneratingCoupons: true,
          pricingUrl: '/wix/pricing',
          type: 'wix',
          showInstall: false,
          showProfile: false,
          showLogout: false,
          displayName: () => userData.platform.shop,
          installCheck: async () => {
            const userId = userData.userId
            const platform = userData.platform
            const { data } = await axios.post('/api/wix/checkInstall', {
              userId,
              platform
            })
            return data.installed
          },
          enableScript: async () => {
            try {
              const result = await axios.post('/api/wix/embedScript', {
                userData
              })
              return result.data?.success
            } catch (e) {
              return false
            }
          },
          sync: async () => {
            if (Cookies.get('impersonate-login')) return
            const timezone =
              Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'Asia/Tokyo'
            const preferredLanguage = detectLang()
            return await axios.post(`/api/wix/syncInfomation`, {
              userData,
              timezone,
              preferredLanguage
            })
          }
        }
        break

      default:
        // For generic just return default obj
        break
    }
  }

  return platformCapabilities
}

export const validUrl = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(url)
}

/*
 * @param emails: should be comma separated
 */
export const validEmail = (emails) => {
  return emails.split(',').every((email) => {
    return !!email
      .trim()
      .match(/^[^\s()<>:;,@\\"]+@[\w\d][\w\d\-]+(\.[\w\d\-]+){0,3}$/)
  })
}

export const isGame = (displayType) => {
  return typesOfGame.includes(displayType)
}

export const getCouponOnThankYou = (display) => {
  const couponContents = display.thankYouContents.find(
    (content) => content.type === 'coupon'
  )

  if (!couponContents) return null

  return couponContents?.coupon
}

export const getCouponOnMain = (display) => {
  const couponContents = display.contents.find(
    (content) => content.type === 'coupon'
  )
  if (!couponContents) return null

  return couponContents.staticCoupon
}

export const detectLang = () => {
  const acceptedLang = ['ja', 'en']
  const localStorageLang =
    typeof localStorage !== 'undefined' && localStorage.getItem('i18nextLng')
  const currentLang = acceptedLang.includes(localStorageLang)
    ? localStorageLang
    : false

  if (currentLang) return currentLang
  else {
    const userLocale = getUserLocale()
    return userLocale.substr(0, 2).toLowerCase() === 'ja' ? 'ja' : 'en'
  }
}

export const gtagConfig = (url) => {
  if (window.gtag && window.plUser.id && !window.plUser.admin) {
    window.gtag('js', new Date())
    window.gtag('config', 'G-JZCXJ6JWLR', {
      page_path: url,
      user_id: window.plUser.id
    })
  }
}

// log specific events happening.
export const gtagEvent = ({ action, params }) => {
  if (!window.plUser.admin) window.gtag && window.gtag('event', action, params)
}

const fontLabelMap = {
  regular: 'Regular',
  italic: 'Italic',
  100: 'Thin 100',
  '100italic': 'Thin 100 italic',
  200: 'Extra-light 200',
  '200italic': 'Extra-light 200 italic',
  300: 'Light 300',
  '300italic': 'Light 300 italic',
  400: 'Regular 400',
  '400italic': 'Regular 400 italic',
  500: 'Medium 500',
  '500italic': 'Medium 500 italic',
  600: 'Semi-bold 600',
  '600italic': 'Semi-bold 600 italic',
  700: 'Bold 700',
  '700italic': 'Bold 700 italic',
  800: 'Semi-black 800',
  '800italic': 'Semi-black 800 italic',
  900: 'Black 900',
  '900italic': 'Black 900 italic'
}

export const formatFontWeightLabel = (key) => {
  return fontLabelMap[key]
}

export const getIcon = (type) => {
  return (
    <span>
      {type === 'popup' && (
        <img
          style={{ width: '100%', maxWidth: '32px' }}
          src="/assets/images/icons/popup-highlighted.svg"
        />
      )}
      {type === 'full-screen' && (
        <img
          style={{ width: '100%', maxWidth: '32px' }}
          className="dashicon"
          src="/assets/images/icons/fullscreen-highlighted.svg"
        />
      )}
      {type === 'slide-in' && (
        <img
          style={{ width: '100%', maxWidth: '32px' }}
          className="dashicon"
          src="/assets/images/icons/slidein-highlighted.svg"
        />
      )}
      {type === 'header-footer' && (
        <img
          style={{ width: '100%', maxWidth: '32px' }}
          className="dashicon"
          src="/assets/images/icons/headerfooter-highlighted.svg"
        />
      )}
      {type === 'spin-to-win' && (
        <img
          style={{ width: '100%', maxWidth: '32px' }}
          className="dashicon"
          src="/assets/images/icons/spintowin-highlighted.svg"
        />
      )}
      {type === 'present' && (
        <img
          style={{ width: '100%', maxWidth: '32px' }}
          className="dashicon"
          src="/assets/images/icons/present-highlighted.svg"
        />
      )}
    </span>
  )
}

export const getTypeLabel = (type) => {
  const typeMap = {
    popup: 'Popup',
    'slide-in': 'Slide in',
    'header-footer': 'Header/Footer',
    inline: 'Inline',
    'full-screen': 'Full screen',
    'spin-to-win': 'Spin to win'
  }
  return typeMap[type]
}

export const getURLOfFileInFirebaseStorage = (filePath, version = 0) => {
  return `https://firebasestorage.googleapis.com/v0/b/unlimited-spin-to-win.appspot.com/o/${encodeURIComponent(filePath)}?alt=media&v=${version}`
}

export const getThumbnail = (display) => {
  return display.thumbnailSrc
    ? getURLOfFileInFirebaseStorage(display.thumbnailSrc)
    : '/assets/images/no-image.png'
}

export const posthogEvent = ({ eventType, key, value }) => {
  if (window?.posthog) {
    switch (eventType) {
      case 'identify':
        window.posthog.identify(key, value)
        break
      case 'capture':
        window.posthog.capture(key, value)
        break
      case 'reset':
        window.posthog.reset()
        break
    }
  }
}
